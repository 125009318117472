<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-16 20:40:23
 * @Description  : 優惠券
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <span style="font-size: 24px">請輸入優惠代碼</span>

            <van-field style="margin-top: 20px" v-model="couponCode" />

            <div class="layout">
                <div class="left">
                    <p style="font-size: 14px">
                        免費小時數：{{ this.freeHour }}小時
                    </p>
                    <p style="font-size: 14px">
                        免費日期截至：{{ this.expireDate }}
                    </p>
                </div>
                <div class="right">
                    <div class="confirm" @click="confirm">確認</div>
                </div>
            </div>

            <!-- 優惠券列表 -->
            <div>
                <van-empty
                    description="暫無優惠券"
                    v-if="couponList.length <= 0"
                >
                    <template #image>
                        <van-image
                            class="custom-image"
                            :src="
                                require('@/assets/imgs/png/icon_content_partner.png')
                            "
                        ></van-image>
                    </template>
                    <template #description> 暫無優惠券 </template>
                </van-empty>
                <div
                    v-for="(couponItem, couponIndex) in couponList"
                    :key="couponIndex"
                >
                    <div class="coupon-layout">
                        <div class="left">
                            <!-- getDuration -->
                            <p>免費租借{{ couponItem.duration }}小時</p>
                            <!-- //getExpireDate -->
                            <p>使用期限:{{ couponItem.expireDate }}</p>
                            <p
                                style="background: #9fce7e"
                                v-if="couponItem.couponType.value === 'AGENT'"
                            >
                                限定代理商{{ couponItem.target }}使用
                            </p>
                            <p
                                style="background: #fffcc8"
                                v-if="couponItem.couponType.value === 'STORE'"
                            >
                                限定{{ couponItem.target }}使用
                            </p>
                            <p
                                style="background: #a9ddfc"
                                v-if="couponItem.couponType.value === 'DEVICE'"
                            >
                                限定機櫃{{ couponItem.target }}使用
                            </p>
                        </div>
                        <div class="right">
                            <div style="font-size: 16px; margin-right: 10px">
                                {{ couponItem.duration }}hr FREE
                            </div>
                            <van-image
                                width="20"
                                :src="require('@/assets/imgs/png/icon_map.png')"
                            ></van-image>
                        </div>
                    </div>
                </div>
            </div>
            <van-divider
                :style="{ color: '#333333', borderColor: '#333333' }"
            ></van-divider>

            <p>說明：</p>
            <div>
                在租借結束時，會自動使用折抵時數最高之優惠券，每次租借僅可使用單張優惠券
            </div>
        </div>

        <div></div>
        <div class="bottom-name">
            <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
        </div>
        <div></div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import { Toast } from "vant";

export default {
    name: "",
    data() {
        return {
            couponList: [],
            page: "1",
            couponCode: "",
            // userInfo: {},
			freeHour:0,
			expireDate:""
        };
    },
    methods: {
        async getCouponList() {
            try {
                const res = await this.$axios.get(
                    `customer/coupons?status=UNUSED&page=${this.page}&size=100`
                );
                if (res.data.success) {
                    console.log("res: ", res);
                    this.couponList = res.data.result.records;
                } else {
                    throw res;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
        async confirm() {
            if (!this.couponCode) {
                handleErr({ app: this.$parent, msg: "輸入內容不能為空" });
                return;
            }
            try {
                const res = await this.$axios.get(
                    `customer/exchange?couponCode=${this.couponCode}`
                );
                console.log("res: ", res);
                if (res.data.success) {
                      Toast("兌換成功");
                    this.getCouponList();
                } else {
                      Toast(res.data.msg);
                }
            } catch (error) {
                console.log("error: ", error);
                handleErr({ ...error, app: this.$parent });
            }
        },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
					this.freeHour=res.data.result.freeHour;
					this.expireDate=res.data.result.expireDate;
                } 
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        // const token = localStorage.getItem("token");
		const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        // this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getUserInfo();
        // 投保接口处理
        this.getCouponList();
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    padding: 20px;
    background: #f8f8f8;
    .layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            flex-direction: column;
        }

        .right {
            .confirm {
                background: #2c394e;
                border-radius: 25px;
                height: 50px;
                color: #ffffff;
                line-height: 50px;
                margin: 20px;
                width: 120px;
                text-align: center;
            }
        }
    }
    .coupon-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: #ffffff;
        padding: 10px;
        margin-top: 10px;

        .left {
            display: flex;
            flex-direction: column;
        }

        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .custom-image .van-empty__image {
        width: 90px;
        height: 90px;
    }
}
</style>
